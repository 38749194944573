<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">commitees management</div>
      </v-row>
      <v-row class="">
        <div class="">
          Create, edit and remove committees and joint committees. View members
          in each commitee.
        </div>
      </v-row>
    </v-col>

    <div class="mt-3 pa-2">
      <div class="d-flex">
        <v-dialog width="auto" v-model="addDialog" :persistent="addingLoader">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-on="on" v-bind="attrs"
              ><v-icon>mdi-plus</v-icon>new commitee</v-btn
            >
          </template>

          <v-card width="500">
            <v-card-title>
              <p class="text-uppercase mx-auto">new commitee</p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-form v-model="addForm" ref="addForm">
                <v-text-field
                  label="Name"
                  outlined
                  dense
                  :rules="[rules.required]"
                  v-model="addName"
                ></v-text-field>
                <v-select
                  label="Type"
                  :rules="[rules.required]"
                  outlined
                  dense
                  :items="['Sectoral', 'Select', 'Joint']"
                  v-model="addCommiteeType"
                ></v-select>
                <v-autocomplete
                  v-if="addCommiteeType === 'Joint'"
                  label="Select Commitees"
                  :rules="[rules.required]"
                  :items="commiteeNames"
                  outlined
                  dense
                  chips
                  multiple
                  counter="2"
                  :menu-props="{ closeOnClick: true }"
                  v-model="addJointCommitees"
                ></v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="addDialog = false"> cancel </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="!addForm"
                :loading="addingLoader"
                @click="commiteeCreate"
              >
                create commitee
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>

        <v-text-field
          label="Search"
          append-icon="mdi-magnify"
          outlined
          dense
          v-model="search"
        ></v-text-field>
      </div>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="commitees"
        show-expand
        :single-expand="true"
        item-key="name"
        class="text-capitalize elevation-10 mt-2"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn color="primary" icon @click="editItem(item)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              icon
              @click="(deleteDialog = true), (deletedItemId = item.id)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.addMembers`]="{ item }">
          <v-btn
            x-small
            color="primary"
            rounded
            @click="
              (addMembersDialog = true), (selected_committee_id = item.id)
            "
            :disabled="item.type == 'joint'"
          >
            <v-icon left>mdi-plus</v-icon> add members</v-btn
          >
        </template>
        <template v-slot:expanded-item="{ item }">
          <v-list subheader dense>
            <v-subheader class="text-uppercase black--text">
              {{ item.name }} members
            </v-subheader>
            <v-list-item v-for="item in item.users" :key="item.name">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> {{ item.name }} </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-data-table>

      <!-- edit dialog -->
      <v-dialog width="auto" v-model="editDialog" :persistent="editLoader">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">edit commitee</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-2">
            <v-form v-model="editForm" ref="editForm">
              <v-text-field
                label="Name"
                outlined
                dense
                :rules="[rules.required]"
                v-model="editName"
              ></v-text-field>
              <v-select
                label="Type"
                :rules="[rules.required]"
                outlined
                dense
                :items="['Sectoral', 'Select', 'Joint']"
                v-model="editCommiteeType"
              ></v-select>
              <v-autocomplete
                v-if="editCommiteeType === 'Joint'"
                label="Select Commitees"
                :rules="[rules.required]"
                :items="['commitee 1', 'commitee 2', 'commitee 3']"
                outlined
                dense
                chips
                multiple
                counter="2"
                :menu-props="{ closeOnClick: true }"
                v-model="editJointCommitees"
              ></v-autocomplete>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="editDialog = false"> cancel </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="editLoader"
              :disabled="!editForm"
              @click="commiteeEdit"
            >
              save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- delete dialog -->

      <v-dialog v-model="deleteDialog" width="auto" :persistent="deleteLoader">
        <v-card>
          <v-card-title>
            <p class="mx-auto text-uppercase">delete commitee</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="text-center text-subtitle-1">
              Are you sure you want to delete this item?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="deleteDialog = false">
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              :loading="deleteLoader"
              @click="commiteeDelete"
            >
              delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- add members dialog -->
    <v-dialog v-model="addMembersDialog" width="auto">
      <v-card width="500" class="mx-auto">
        <v-card-title>
          <p class="mx-auto text-uppercase">add members to committee</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="addMembersForm" v-model="addMembersForm">
            <v-autocomplete
              :items="memberNames"
              dense
              outlined
              label="Select members to add to committee"
              :rules="[rules.required]"
              v-model="membersToAdd"
              chips
              multiple
            />
            <v-btn
              color="primary"
              :disabled="!addMembersForm"
              :loading="addMembersLoader"
              block
              @click="addMembers()"
              >add members</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-capitalize text-center">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { commiteeStore } from "../stores/commitees";
import { mapState, mapActions } from "pinia";
import { memberStore } from "../stores/members";

export default {
  data() {
    return {
      addName: "",
      editName: "",
      addCommiteeType: "",
      editCommiteeType: "",
      editDialog: false,
      deleteDialog: false,
      addJoint: false,
      addJointCommitees: [],
      editJointCommitees: [],
      addForm: false,
      editForm: false,
      addingLoader: false,
      editLoader: false,
      deleteLoader: false,
      addDialog: false,
      rules: {
        required: (v) => !!v || "Required",
      },
      search: "",
      headers: [
        {
          text: "name",
          value: "name",
          class: "text-uppercase black--text",
        },
        {
          text: "type",
          value: "type",
          class: "text-uppercase black--text",
        },
        {
          text: "add members",
          value: "addMembers",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      snackBar: false,
      snackBarData: {
        success: null,
        message: "",
      },
      editedItemId: null,
      addMembersForm: false,
      addMembersDialog: false,
      addMembersLoader: false,
      membersToAdd: [],
      selected_committee_id: null,
    };
  },
  computed: {
    ...mapState(commiteeStore, ["commiteeNames", "commitees"]),
    ...mapState(memberStore, ["memberNames", "members"]),
  },
  methods: {
    ...mapActions(commiteeStore, [
      "getCommitees",
      "addCommiteeMembers",
      "createCommitee",
      "editCommitee",
      "deleteCommitee",
    ]),
    ...mapActions(memberStore, ["getMembers"]),
    editItem(item) {
      this.editedItemId = item.id;
      // auto fill
      this.editName = item.name;
      this.editCommiteeType = item.type;
      if (item.type === "Joint") {
        this.editJointCommitees = item.joint;
      }
      this.editDialog = true;
    },
    commiteeCreate() {
      this.addingLoader = true;

      // get the selected commitee id's
      let ids = [];
      if (this.addCommiteeType != "Single")
        for (let name of this.addJointCommitees) {
          // get the id of the committee
          for (let comm of this.commitees) {
            if (name === comm.name) {
              ids.push(comm.id);
            }
          }
        }

      this.createCommitee({
        name: this.addName,
        type: this.addCommiteeType,
        committee_ids: ids,
      })
        .then(() => {
          this.$refs.addForm.reset();
          this.showSnackBar(true, "commitee created succesfully");
        })
        .catch(() => {
          this.showSnackBar(false, "failed to create committee");
        })
        .finally(() => {
          this.addDialog = false;
          this.addingLoader = false;
        });
    },
    addMembers() {
      this.addMembersLoader = true;
      let ids = [];
      for (let name of this.membersToAdd) {
        for (let member of this.members) {
          if (member.name == name) {
            ids.push(member.id);
          }
        }
      }
      this.addCommiteeMembers({
        user_ids: ids,
        committee_type_id: this.selected_committee_id,
      })
        .then(() => {
          this.$refs.addMembersForm.reset();
          this.showSnackBar(true, "Members added succesfully");
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.addMembersDialog = false;
          this.addMembersLoader = false;
        });
    },
    commiteeEdit() {
      this.editLoader = true;
      this.editCommitee(this.editedItemId, {
        name: this.editName,
        type: this.editCommiteeType,
        commitees: this.editJointCommitees,
      })
        .then(() => {
          this.$refs.editForm.reset();
          this.showSnackBar(true, "committee edited succesfully");
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.editDialog = false;
          this.editLoader = false;
        });
    },
    commiteeDelete() {
      this.deleteLoader = true;
      this.deleteCommitee(this.deletedItemId)
        .then(() => {
          this.showSnackBar(true, "committee deleted succesfully");
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.deleteDialog = false;
          this.deleteLoader = false;
        });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  mounted() {
    this.getCommitees();
    this.getMembers();
  },
};
</script>

<style></style>
